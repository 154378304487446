* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #121212;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}
